/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ConnectionStatus {
  Online,
  Offline
}
@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject<ConnectionStatus>(ConnectionStatus.Offline);
  constructor(private toastController: ToastController, private plt: Platform) {
    this.plt.ready().then(() => {
      this.initializeNetworkEvents();
    });
  }

  /** Is the device offline */
  get isOffline(): boolean {
    return this.getCurrentNetworkStatus() === ConnectionStatus.Offline;
  }

  public async initializeNetworkEvents() {
    const status = await Network.getStatus();
    this.status.next(status.connected ? ConnectionStatus.Online : ConnectionStatus.Offline);

    Network.addListener('networkStatusChange', status => {
      if (status.connected) {
        this.updateNetworkStatus(ConnectionStatus.Online);
      } else {
        this.updateNetworkStatus(ConnectionStatus.Offline);
      }
    });
  }

  async presentToast(message: string, duration = 0) {
    this.toastController.dismiss(undefined, undefined, 'networkStatusToast');
    const toast = await this.toastController.create({
      id: 'networkStatusToast',
      message,
      duration
    });
    toast.present();
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }

  private async updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);
    const con = status === ConnectionStatus.Offline ? 'Offline' : 'Online';
    const duration = status === ConnectionStatus.Offline ? 0 : 3000;
    this.presentToast(`You are now ${con}`, duration);
  }
}
