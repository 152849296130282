import { IdmService } from '../services/idm/idm.service';
import { Injectable } from '@angular/core';
import { Command } from './command.interface';

@Injectable({
  providedIn: 'root'
})
export class RequestOtp implements Command<string, void> {
  constructor(private idmService: IdmService) {}

  execute(username: string) {
    return this.idmService.requestOtp(username);
  }
}
