import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, from, Observable, of, throwError, forkJoin } from 'rxjs';
import { map, shareReplay, switchMap, tap, catchError, share, skipWhile } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http/http';
import { CourseTileData, CourseEnrollment, CourseBucketByStatus } from '../../models/course.interface';
import { Lesson, ActivityTopic } from '../../models';
import { PijamHttpClient } from '../../http-client/pijam-http-client';
import { endpoints } from '../../constants/endpoints';
import { NetworkService } from '../network.service';
import { CapStorageService } from '../storage/cap-storage.service';
import { PIJAM_API_CLIENT_CONFIGURATION_TOKEN, Configuration } from '../../interfaces/configuration';
import { IdmService } from '../idm/idm.service';
import { LessonService } from './lesson.service';
import { BadgesService } from './badges.service';
import { RewardService } from './rewards.service';
@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  /** Emits a value when the course should be refetched */
  #refetch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  readonly #emptyImage = 'R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';

  private _currentTopicIndex: BehaviorSubject<number> = new BehaviorSubject(-1);

  private course$!: Observable<CourseEnrollment>;
  private courses$ = new BehaviorSubject<CourseTileData[] | null>(null);
  private courseEnrollments$ = new BehaviorSubject<CourseEnrollment[] | null>(null);
  private lesson!: Lesson;

  /** Key for storing current course */
  public static courseStorageKey = 'current-course';

  constructor(
    private rewardService:RewardService,
    private pijamHttpClient: PijamHttpClient,
    private network: NetworkService,
    private storage: CapStorageService,
    private lessonService: LessonService,
    private idm: IdmService,
    @Inject(PIJAM_API_CLIENT_CONFIGURATION_TOKEN) private configuration: Configuration,
    private badgesService: BadgesService
  ) {}

  get currentTopicIndex() {
    return this._currentTopicIndex.asObservable();
  }

  updateCurrentTopicIndex(newIndex: number) {
    this._currentTopicIndex.next(newIndex);
  }

  /** Retrieves the current course from storage */
  get currentCourse(): Promise<CourseEnrollment> {
    return this.storage.getValue(CoursesService.courseStorageKey);
  }

  refreshActiveCourses() {
    this.pijamHttpClient.get<CourseBucketByStatus[]>(endpoints.LIST_COURSES_BY_STATUS).pipe(
      map(courseBuckets => courseBuckets.find(courseBucket => courseBucket.status == 'Active')),
      map(courseBucket => courseBucket ? courseBucket.courses.map(
        course => ({
          id: course.course_id,
          status: courseBucket.status,
          image: course.course_img === this.#emptyImage ? '' : course.course_img,
          name: course.course_name,
          short_desc: ''
        } as CourseTileData)
      ) : []),
      shareReplay(1)
    )
      .subscribe(courses => {
        console.log(courses, 'refresh');
        this.courses$.next(courses);
      });
  }

  getActiveCourses(): Observable<CourseTileData[] | null> {
    return this.courses$.asObservable();
  }

  getActiveCoursesEnrollments(): Observable<CourseEnrollment[] | null> {
    return this.courseEnrollments$.asObservable();
  }

  refreshActiveCoursesEnrollments() {
    this.getActiveCourses().pipe(
      skipWhile(courses => courses === null),
      switchMap(courses => courses ? forkJoin(
        courses.map(
          course => this.callCourseApiEndpoint(course.id).pipe(
            catchError(() => of({ course: null as unknown as CourseEnrollment }))
          ))
      ) : []),
      map(
        courseEnrollments => courseEnrollments
          .map(
            ({ course }) => course
          ).filter(
            course => !!course
          )
      ),
      shareReplay(1)
    )
      .subscribe(enrollments => this.courseEnrollments$.next(enrollments));
  }

  /** Get the current course from API if connected and from storage if offline */
  getCourse(courseId = 1, forceRefresh = false) {
    if (forceRefresh || !this.course$) {
      this.course$ = this.network.isOffline
        ? from(this.currentCourse)
        : this.fetchCourse(courseId); // TODO: Set the actual courseId to load, do not default to 1
    }
    return this.course$;
  }


  getLesson() {
    return this.lesson;
  }

  setLesson(lesson: Lesson) {
    this.lesson = lesson;
  }

  async setNextLesson() {
    const currentCourse = await this.currentCourse;
    const nextLesson = currentCourse.stages.reduce<Lesson>((nextLesson, stage) => {
      const nextLessonIndex = stage.lessons.reduce((lessonIndex, lesson, index) => {
        if (lesson.lesson_id == this.lessonService.currentLessonId) {
          lessonIndex = index + 1;
        }

        return lessonIndex;
      }, -1);

      if (nextLessonIndex != -1) {
        nextLesson = stage.lessons[nextLessonIndex];
      }

      return nextLesson;
    }, null as unknown as Lesson);

    if (nextLesson) {
      this.lessonService.currentLesson = nextLesson;
    }
  }

  /** Get the url to download and view the content for a topic */
  getTopicVideoUrl(topicId: number, resolution = 'lo'): Observable<string> {
    const endpoint = endpoints.TOPIC_DOWNLOAD_URL + `?topic_id=${topicId}&res=${resolution}`;
    return this.pijamHttpClient.get<string>(endpoint).pipe(
      map((response: string) => JSON.parse(response)?.signedUrl ?? '')
    );
  }

  setTopicComplete(topicId: number) {
    return this.pijamHttpClient.post(
      endpoints.UPDATE_TOPIC_STATUS,
      {
        status: 'complete', topic_id: topicId, username: this.idm.username
      }
    ).pipe(
      tap(response => {
        // Check if "updated_Rewards" exists in the response
        if (response.hasOwnProperty('updated_Rewards')) {
          const updatedRewards = response.updated_Rewards;
          // Call the setRewards function from the reward service
          this.rewardService.setRewards(updatedRewards);
        }
        // this.refetch();
        // this.refreshActiveCourses();
        // this.refreshActiveCoursesEnrollments();
        this.badgesService.taskCompleted(5, 0); // TODO: Add actual time and hints used
        this.badgesService.checkForNewBadge();
        // this.fetchCourse(1)
        // console.log("setTopicComplete from api")
      })
    );
  }

  /** Get the url for a topic activity  */
  getTopicActivityUrl(topic: ActivityTopic) {
    return of(`${this.configuration.topicActivityUrl}/${topic.info.folder}/${topic.info.name}_modal.html`);
  }

  loadAssessment() {
    return 'https://pijam-scratch-mobile1-dist.s3.ap-south-1.amazonaws.com/Umeshmaze/maze.html';
  }

  /** Refetch the current course */
  refetch() {
    this.#refetch$.next(true);
  }

  /**  Fetch the course with the given courseId from the server */
  private fetchCourse(courseId: number | string = 1): Observable<CourseEnrollment> {
    return this.#refetch$.pipe(
      switchMap(
        () => this.callCourseApiEndpoint(courseId)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              /**
               * Catch 404 errors, equivalent to a user not being enrolled yet, and call enroll backend endpoint,
               * then try fetching the course enrollment information again
               */
              if (error.status === 404) {
                return this.pijamHttpClient.post(
                  endpoints.ENROLL_COURSE,
                  {
                    username: this.idm.username,
                    course_id: courseId
                  }
                ).pipe(
                  switchMap(
                    () => {
                      // mixpanel.track(MixpanelEvents.ENROLL_INTO_COURSE, {
                      //   phone_number: this.pijamHttpClient.getConfig('mobile_num')
                      // });
                      return this.callCourseApiEndpoint(courseId);
                    }
                  )
                );
              } else {
                return throwError(error);
              }
            })
          )
      ),
      map((courseDetails: { course: CourseEnrollment }) => courseDetails.course),
      tap(courseDetails => {
        this.storage.setValue(CoursesService.courseStorageKey, courseDetails)
      }),
      shareReplay(1)
    );
  }

  private callCourseApiEndpoint(courseId: string | number = 1) {
    const mobileNum = encodeURIComponent(this.idm.username);

    return this.pijamHttpClient.get<{ course: CourseEnrollment }>(
      `${endpoints.GET_ENROLL_DETAIL}?username=${mobileNum}&courseid=${courseId}`
    ).pipe(share());
  }
}

const COURSES = [
    {
        "status": "Active",
        "course_count": 3,
        "courses": [
            {
                "course_id": 252,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 23,
                "created_at": "27/11/2022",
                "course_name": "Marathi"
            },
            {
                "course_id": 253,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 143,
                "created_at": "27/11/2022",
                "course_name": "Programming with Blocks 2"
            },
            {
                "course_id": 1,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 63,
                "created_at": "15/09/2021",
                "course_name": "Programming with Blocks"
            }
        ]
    },
    {
        "status": "Archive",
        "course_count": 3,
        "courses": [
            {
                "course_id": 246,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 5,
                "created_at": "25/11/2022",
                "course_name": "Do it"
            },
            {
                "course_id": 235,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 3,
                "created_at": "22/11/2022",
                "course_name": "Do it now"
            },
            {
                "course_id": 214,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 3,
                "created_at": "29/09/2022",
                "course_name": "Add Course Title"
            }
        ]
    },
    {
        "status": "Draft",
        "course_count": 67,
        "courses": [
            {
                "course_id": 278,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "17/12/2022",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 169,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 149,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 280,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "23/01/2023",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 88,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "ihjhhn"
            },
            {
                "course_id": 125,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 40,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "C1"
            },
            {
                "course_id": 291,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 16,
                "created_at": "15/03/2023",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 81,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "1212"
            },
            {
                "course_id": 251,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 27,
                "created_at": "27/11/2022",
                "course_name": "Teacher Training NEW"
            },
            {
                "course_id": 171,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "30/11/2021",
                "course_name": "Test"
            },
            {
                "course_id": 96,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "C1"
            },
            {
                "course_id": 105,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 107,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 233,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 2,
                "created_at": "22/11/2022",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 220,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "19/10/2022",
                "course_name": "Pijam Course"
            },
            {
                "course_id": 86,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "lkmkl"
            },
            {
                "course_id": 89,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 175,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "02/12/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 231,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "22/11/2022",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 75,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "A3"
            },
            {
                "course_id": 243,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "23/11/2022",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 38,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "C1"
            },
            {
                "course_id": 78,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "1111"
            },
            {
                "course_id": 250,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "27/11/2022",
                "course_name": "Programming with blocks 2"
            },
            {
                "course_id": 76,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 106,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 129,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 68,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "C2"
            },
            {
                "course_id": 101,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 69,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "AC1"
            },
            {
                "course_id": 112,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 289,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 6,
                "created_at": "15/03/2023",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 124,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 286,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 4,
                "created_at": "15/03/2023",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 279,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 2,
                "created_at": "17/12/2022",
                "course_name": "Open Lab"
            },
            {
                "course_id": 79,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 85,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "ijuhi"
            },
            {
                "course_id": 281,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 4,
                "created_at": "09/02/2023",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 232,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "22/11/2022",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 172,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 2,
                "created_at": "30/11/2021",
                "course_name": "Test "
            },
            {
                "course_id": 131,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 249,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "27/11/2022",
                "course_name": "Teacher Training"
            },
            {
                "course_id": 151,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 74,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "AC3"
            },
            {
                "course_id": 215,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 8,
                "created_at": "29/09/2022",
                "course_name": "Demo Course"
            },
            {
                "course_id": 70,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "AC2"
            },
            {
                "course_id": 190,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/09/2022",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 87,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "jhk"
            },
            {
                "course_id": 67,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/11/2021",
                "course_name": "C1"
            },
            {
                "course_id": 174,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "02/12/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 216,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "30/09/2022",
                "course_name": "Andu's course"
            },
            {
                "course_id": 144,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 163,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 36,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 13,
                "created_at": "27/11/2021",
                "course_name": "C1"
            },
            {
                "course_id": 287,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 3,
                "created_at": "15/03/2023",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 221,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "19/10/2022",
                "course_name": "Video activity course"
            },
            {
                "course_id": 277,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "17/12/2022",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 290,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "15/03/2023",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 99,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 157,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "29/11/2021",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 248,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 27,
                "created_at": "26/11/2022",
                "course_name": "New Course"
            },
            {
                "course_id": 191,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "28/09/2022",
                "course_name": "Add Course Title"
            },
            {
                "course_id": 234,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "22/11/2022",
                "course_name": "Add Course Title"
            }
        ]
    },
    {
        "status": "Draft\n",
        "course_count": 1,
        "courses": [
            {
                "course_id": 245,
                "course_img": "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                "stage_count": 1,
                "created_at": "25/11/2022",
                "course_name": "New Course"
            }
        ]
    }
];
