import { Command } from './command.interface';
import { CoursesService } from '../services/courses/courses.service';
import { Injectable } from '@angular/core';
import { CourseEnrollment } from '../models';
import { Observable } from 'rxjs';

export interface GetCourseOptions {
  courseId: number;
  forceRefresh?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class GetCourse implements Command<GetCourseOptions, CourseEnrollment> {
  courseId!: number;
  forceRefresh = false;

  constructor(private coursesService: CoursesService) {}

  execute(options: GetCourseOptions): Observable<CourseEnrollment> {
    const { courseId, forceRefresh = false } = options;

    return this.coursesService.getCourse(courseId, forceRefresh);
  }
}
