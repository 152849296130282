import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Lesson, ActivityTopic } from '../../models';
import { PijamHttpClient } from '../../http-client/pijam-http-client';
import { CapStorageService } from '../storage/cap-storage.service';
import { IdmService } from '../idm/idm.service';
import { endpoints } from '../../constants/endpoints';
import { HocEnrollment, HocExercise } from '../../models/HourOfCode.model';
import { PIJAM_API_CLIENT_CONFIGURATION_TOKEN, Configuration } from '../../interfaces/configuration';
import { NetworkService } from '../network.service';
import { BadgesService } from '../courses/badges.service';
import { RewardService } from '../courses/rewards.service'; 
@Injectable({
  providedIn: 'root'
})
export class HourOfCodeService {
  /** Emits a value when the course should be refetched */
  #refetch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public navSub: Subject<number> = new Subject();
  private storedHocId!: number | string ;
  private course$!: Observable<HocEnrollment>;
  private lesson!: Lesson;
  private _currentTopicIndex: BehaviorSubject<number> = new BehaviorSubject(-1);
  /** Key for storing current course */
  public static hocStorageKey = 'current-hoc';

  constructor(
    private rewardService:RewardService,
    private pijamHttpClient: PijamHttpClient,
    private network: NetworkService,
    private storage: CapStorageService,
    private idm: IdmService,
    @Inject(PIJAM_API_CLIENT_CONFIGURATION_TOKEN) private configuration: Configuration,
    private badgesService: BadgesService
  ) { }

  setHocId(hocId: number | string): void {
    this.storedHocId = hocId;
  }

  getHocId(): number | string {
    return this.storedHocId;
  }

  clearHocId(): void {
    this.storedHocId = '';
  }
  
  /** Retrieves the current course from storage */
  get currentCourse(): Promise<HocEnrollment> {
    return this.storage.getValue(HourOfCodeService.hocStorageKey);
  }

  /** Get the current course from API if connected and from storage if offline */
  getCourse(hocId: number, forceRefresh = false): Observable<HocEnrollment> {
    if (forceRefresh || !this.course$ || this.storedHocId !== hocId) {
      // console.log(this.storedHocId,hocId)
      this.course$ = this.network.isOffline
        ? from(this.currentCourse)
        : this.fetchCourse(hocId);
      this.setHocId(hocId);
    }
    return this.course$;
  }
  

  get currentTopicIndex() {
    return this._currentTopicIndex.asObservable()
  }

  updateCurrentTopicIndex(newIndex: number) {
    this._currentTopicIndex.next(newIndex)
  }

  getLesson() {
    return this.lesson;
  }

  setLesson(lesson: Lesson) {
    this.lesson = lesson;
  }

  /** Get the url to download and view the content for a topic */
  getTopicVideoUrl(topicId: number, resolution = 'lo'): Observable<string> {
    const endpoint = endpoints.TOPIC_DOWNLOAD_URL + `?topic_id=${topicId}&res=${resolution}`;
    return this.pijamHttpClient.get<string>(endpoint).pipe(
      map((response: string) => JSON.parse(response)?.signedUrl ?? '')
    );
  }

  setTopicComplete(topicId: number) {
    return this.pijamHttpClient.post(
      endpoints.UPDATE_TOPIC_STATUS_HOC,
      {
        status: 'complete', topic_id: topicId, username: this.idm.username
      }
    ).pipe(
      tap(response => {
        // Check if "updated_Rewards" exists in the response
        if (response.hasOwnProperty('updated_Rewards')) {
          const updatedRewards = response.updated_Rewards;
          // Call the setRewards function from the reward service
          this.rewardService.setRewards(updatedRewards);
        }
        // console.log("hoc setTopicComplete")
        this.refetch();
        this.badgesService.taskCompleted(5, 0); // TODO: Add actual time and hints used
        this.badgesService.checkForNewBadge();
      })
    );
  }

  /** Get the url for a topic activity  */
  getTopicActivityUrl(topic: ActivityTopic) {
    return of(`${this.configuration.topicActivityUrl}/${topic.info.folder}/${topic.info.name}_modal.html`);
  }

  loadAssessment() {
    return 'https://pijam-scratch-mobile1-dist.s3.ap-south-1.amazonaws.com/Umeshmaze/maze.html';
  }

  /** Refetch the current course */
  refetch() {
    // console.log("refetch HOC service")
    this.#refetch$.next(true);
  }

  fetchCourses(): Observable<HocExercise[]> {
    return this.pijamHttpClient.get<HocExercise[]>(endpoints.GET_ALL_HOC);
  }

  /**  Fetch the course with the given courseId from the server */
  fetchCourse(hocId: number | string = 0): Observable<HocEnrollment> {
    const mobileNum = encodeURIComponent(this.idm.username);
    
    return this.pijamHttpClient.get<{ hoc: HocEnrollment }>(
      `${endpoints.GET_ENROLL_DETAIL_HOC}?username=${mobileNum}&hocid=${hocId}`
    ).pipe(
      map((courseDetails: { hoc: HocEnrollment }) => courseDetails.hoc),
      tap(courseDetails => this.storage.setValue(HourOfCodeService.hocStorageKey, courseDetails)),
      shareReplay(1)
    );
  }
  

  enrollCourse(hocId: number): Observable<any> {
    return this.pijamHttpClient.post(endpoints.ENROLL_HOC, { username: this.idm.username, hoc_id: hocId });
  }
}

