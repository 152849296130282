import { Injectable } from '@angular/core';
import { Command } from './command.interface';
import { HourOfCodeService } from '../services/hoc/hour-of-code.service';
import { BadgesService } from '../services/courses/badges.service';

@Injectable({
  providedIn: 'root'
})
export class SetCurrentHocTopicIndex implements Command<number, void> {
  constructor(private hocService: HourOfCodeService, private badgesService: BadgesService) {}

  execute(newIndex: number) {
    this.hocService.updateCurrentTopicIndex(newIndex);
    this.badgesService.resetTaskProgress();
  }
}
