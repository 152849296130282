import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, take, filter } from 'rxjs/operators';
import { endpoints } from '../constants/endpoints';
import { IdmService } from '../services/idm/idm.service';
import { PijamHttpClient } from './pijam-http-client';
import {
  PIJAM_API_CLIENT_CONFIGURATION_TOKEN,
  Configuration,
} from '../interfaces/configuration';

const nonAccessTokenAuthUrls = [
  endpoints.GENERATE_OTP,
  endpoints.SEND_OTP,
  endpoints.LOGIN_OTP,
  endpoints.SIGNUP,
  endpoints.REFRESH_TOKENS,
];

@Injectable()
export class PijamInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  refreshTokenSubject = new BehaviorSubject<string | null>(null);

  constructor(
    private pijamHttpClient: PijamHttpClient,
    private idm: IdmService,
    @Inject(PIJAM_API_CLIENT_CONFIGURATION_TOKEN)
    private configuration: Configuration
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let requestURL = request.url;

    if (!request.headers.get(`restrictURLPrepend`)) {
      requestURL = this.configuration.apiBaseUrl + requestURL;
    } else {
      request = request.clone({
        headers: request.headers.delete(`restrictURLPrepend`),
      });
    }

    let finalRequest = request.clone({
      url: requestURL,
    });

    if (!this.isNonAccessTokenAuthUrl(requestURL)) {
      if (this.idm.accessToken) {
        finalRequest = this.addAccessToken(finalRequest, this.idm.accessToken);
      }
    }

    return next.handle(finalRequest).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status == 403) {
          if (!this.isNonAccessTokenAuthUrl(finalRequest.url)) {
            return this.handle403Error(finalRequest, next);
          } else {
            this.logout();
          }
        }

        return throwError(err);
      })
    );
  }

  private addAccessToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  private handle403Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      const username = this.idm.username;
      this.refreshTokenSubject.next(null);

      return this.pijamHttpClient
        .post(
          endpoints.REFRESH_TOKENS,
          {},
          {
            customHeaders: new Map([
              ['Authorization', `Bearer ${this.idm.refreshToken}`],
            ]),
          }
        )
        .pipe(
          switchMap(
            ({
              access_token,
              refresh_token,
            }: {
              access_token: string;
              refresh_token: string;
            }) => {
              this.isRefreshingToken = false;

              this.idm.setInfo({
                access_token,
                refresh_token,
                mobile_num: username,
              });
              this.refreshTokenSubject.next(refresh_token);
              return next.handle(this.addAccessToken(req, access_token));
            }
          ),
          catchError((err) => {
            this.isRefreshingToken = false;

            if (
              err &&
              err instanceof HttpErrorResponse &&
              err.url &&
              (err.url.includes(endpoints.REFRESH_TOKENS) || err.status == 403)
            ) {
              this.logout();
            }

            return throwError(err);
          })
        );
    }

    const res = this.refreshTokenSubject.asObservable().pipe(
      filter((token) => token != null),
      take(1),
      switchMap(() => {
        return next.handle(this.addAccessToken(req, this.idm.accessToken));
      })
    );

    return res;
  }

  private isNonAccessTokenAuthUrl(url: string) {
    return [...nonAccessTokenAuthUrls, this.configuration.storeVersionUrl].find(
      (nonAccessTokenAuthUrl) => url.includes(nonAccessTokenAuthUrl)
    );
  }

  private async logout() {
    await this.idm.logout();
  }
}
