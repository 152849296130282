import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PijamInterceptor } from './http-client/pijam.interceptor';
import { Configuration, PIJAM_API_CLIENT_CONFIGURATION_TOKEN } from './interfaces/configuration';

@NgModule({
  declarations: [
  ],
  imports: [
    HttpClientModule
  ],
  exports: [
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: PijamInterceptor, multi: true },
  ]
})
export class NgxPijamApiClientModule {
  static forRoot(configuration: Configuration): ModuleWithProviders<NgxPijamApiClientModule> {
    return {
      ngModule: NgxPijamApiClientModule,
      providers: [
        {
          provide: PIJAM_API_CLIENT_CONFIGURATION_TOKEN,
          useValue: configuration
        }
      ]
    };
  }
}
