import { Command } from './command.interface';
import { Injectable } from '@angular/core';
import { CoursesService } from '../services/courses/courses.service';
import { CourseTileData } from '../models/course.interface';

@Injectable({
  providedIn: 'root'
})
export class GetActiveCourses implements Command<void, CourseTileData[] | null> {
  constructor(private coursesService: CoursesService) {}

  execute() {
    return this.coursesService.getActiveCourses();
  }
}
