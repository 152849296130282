import { Command } from './command.interface';
import { Injectable } from '@angular/core';
import { IdmService } from '../services/idm/idm.service';
import { tap } from 'rxjs/operators';
import { CoursesService } from '../services/courses/courses.service';
import { PointsService } from '../services/courses/points.service';

@Injectable({
  providedIn: 'root'
})
export class loginWithPin implements Command<{username: string,pin: string}, void> {
  constructor(
    private idmService: IdmService,
    private coursesService: CoursesService,
    private pointsService: PointsService
  ) {}

  execute(params: {username: string,pin: string }) {
    return this.idmService.loginWithPin(params.username,params.pin ).pipe(
      tap(() => {
        this.coursesService.refreshActiveCourses();
        this.coursesService.refreshActiveCoursesEnrollments();
        this.pointsService.refreshPoints();
      })
    );
  }
}
