// export enum MixpanelEvents {
//   //for all events app_type:"web", user_type:student/teacher, user_mobile:num)
//   USER_SIGN_UP = 'User signup', //Form data
//   USER_LOG_IN = 'User login', //login_type:OTP/PIN
//   USER_LOG_OUT = 'User logout',
//   VISIT_LMS = 'Visit lms',
//   LMS_CONTENT_WATCHED = 'LMS content watched',
//   LMS_CONTENT_COMPLETED = 'LMS content completed',
//   ACTIVITY_STARTED = 'Activity started', //topic_id,topic_name,topic_type:(video/block/..)
//   ACTIVITY_CLOSED = 'Activity closed', //topic_id,topic_name,topic_type:(video/block/..)
//   ACTIVITY_COMPLETED = 'Activity completed', //topic_id,topic-name,topic_type:(video/block/..)
//   ENROLL_INTO_COURSE = 'Enroll into course', //course ID ,course name
//   VISIT_ACTIVITY_LAB = 'Visit activity lab',
//   VISIT_HOME_PAGE = 'Visit home page',
//   VISIT_LANDING_PAGE = 'Visit landing page',
//   VISIT_REGISTRATION = 'Visit registration page',
//   VISIT_SET_PIN = 'Visit setpin page',
//   VISIT_STUDENT_COURSES = 'Visit student course list',
//   VISIT_STUDENT_LESSONS = 'Visit student lesson list',
//   VISIT_STUDENT_LESSON = 'Visit student lesson',
//   PIN_SET = 'Pin set',
//   VISIT_COMPETITION_LESSONS = 'Visit Competition lessons page',
//   COMPETITION_VIDEO_PLAY_COUNT_TRACKER = 'Competition video play count',
//   VISIT_COMPETITION_DETAILS_PAGE = 'Visit competition details pageVisit',
//   COMPETITION_ENROLLMENT = 'Competition enrollment',
//   COMPETITION_LESSON_COMPLETE = 'Competition lesson complete',
// }

/**
export enum MixpanelPageLabels {
  // USER_SIGN_UP = 'User signup', //Form data
  // USER_LOG_IN = 'User login', //login_type:OTP/PIN
  // USER_LOG_OUT = 'User logout',
  // VISIT_LMS = 'Visit lms',
  // LMS_CONTENT_WATCHED = 'LMS content watched',
  // LMS_CONTENT_COMPLETED = 'LMS content completed',
  // ACTIVITY_STARTED = 'Activity started', //topic_id,topic_name,topic_type:(video/block/..)
  // ACTIVITY_CLOSED = 'Activity closed', //topic_id,topic_name,topic_type:(video/block/..)
  // ACTIVITY_COMPLETED = 'Activity completed', //topic_id,topic-name,topic_type:(video/block/..)
  // ENROLL_INTO_COURSE = 'Enroll into course', //course ID ,course name
  // VISIT_ACTIVITY_LAB = 'Visit activity lab',
  // VISIT_HOME_PAGE = 'Visit home page',
  // VISIT_LANDING_PAGE = 'Visit landing page',
  // VISIT_REGISTRATION = 'Visit registration page',
  // VISIT_SET_PIN = 'Visit setpin page',
  // VISIT_STUDENT_COURSES = 'Visit student course list',
  // VISIT_STUDENT_LESSONS = 'Visit student lesson list',
  // VISIT_STUDENT_LESSON = 'Visit student lesson',
  PIN_SET = 'Pin set',
  // VISIT_COMPETITION_LESSONS = 'Visit Competition lessons page',
  COMPETITION_VIDEO_PLAY_COUNT_TRACKER = 'Competition video play count',
  // VISIT_COMPETITION_DETAILS_PAGE = 'Visit competition details pageVisit',
  COMPETITION_ENROLLMENT = 'Competition enrollment',
  COMPETITION_LESSON_COMPLETE = 'Competition lesson complete',
}
*/

import mixpanel from 'mixpanel-browser';

enum MixpanelEventName {
  PAGE_VIEWED = 'page-viewed',
  BUTTON_CLICKED = 'button-clicked',
  LINK_OPENED = 'link-opened',
  BANNER_CLICKED = 'banner-clicked',
  CHECKPOINT = 'checkpoint',
}

// Page view events
const PageLabel = {
  LMS: 'lms',
  ACTIVITY__LAB: 'activity-lab',
  LANDING: 'landing',
  HOME: 'home',
  REGISTRATION: 'registration',
  SET_PIN: 'set-pin',
  STUDENT_COURSES_LIST: 'student-courses-list',
  STUDENT_LESSONS_LIST: 'student-lessons-list',
  STUDENT_LESSON: 'student-lesson',
  COMPETITION_LESSONS_LIST: 'competition-lessons-list',
  COMPETITION_HOME: 'competition-home',
  COMPETITION_FEEDBACK: 'competition-feedback',
  COMPETITION_CERTIFICATE: 'competition-certificate',
  COMPETITION_TOPICS: 'competition-topics',
  COMPETITION_REFERRAL: 'competition-referral',
};
export const pageViewed = (
  label: keyof typeof PageLabel,
  properties: { [key: string]: string } = {}
) => {
  mixpanel.track(MixpanelEventName.PAGE_VIEWED, {
    label: PageLabel[label],
    ...properties,
  });
};

// Button clicked events
const ButtonName = {
  COURSE_ENROLLMENT: 'enroll-into-course',
  GO_TO_COURSE: 'go-to-course',
  MULTIMEDIA_OPTION: "select-multimedia-topic-option",
  MULTIMEDIA_OPTION_BACK: "back-to-multimedia-option-selection",
  START_RECORDING: "start-recording",
  STOP_RECORDING: "stop-recording",
  PLAY_PREVIEW: "play-preview",
  STOP_PREVIEW: "stop-preview",
  SUBMIT_AND_CONTINUE: "submit-and-continue",
  SUBMIT_FEEDBACK: "submit-feedback",
  DOWNLOAD_CERTIFICATE: "download-certificate",
  GO_TO_HOME: "go-to-home",
  VIEW_TASK: "view-task",
  DOWNLOAD_CERTIFICATE_REDIRECT: "download-certificate-redirect",
  FORM_SUBMIT: "form-submit",
  LANGUAGE_CHANGE: "language-change",
  DETAILS_CTA_CLICKED: "details-cta-clicked",
};
export const buttonClicked = (
  label: keyof typeof ButtonName,
  properties: { [key: string]: string } = {}
): Promise<void> => {
  return new Promise((resolve) => {
    mixpanel.track(MixpanelEventName.BUTTON_CLICKED, {
      label: ButtonName[label],
      ...properties,
    });
    resolve(); 
  });
};


// Link clicked
const LinkName = {
  // ...Add link names here...
};
export const LinkOpened = (
  label: keyof typeof LinkName,
  properties: { [key: string]: string } = {}
) => {
  mixpanel.track(MixpanelEventName.LINK_OPENED, {
    label: LinkName[label],
    ...properties,
  });
};

// Banner clicked
const BannerName = {
  // ...Add link names here...
};
export const bannerClicked = (
  label: keyof typeof BannerName,
  properties: { [key: string]: string } = {}
) => {
  mixpanel.track(MixpanelEventName.BANNER_CLICKED, {
    label: BannerName[label],
    ...properties,
  });
};

// Checkpoint Events
const CheckpointName = {
  USER_SIGNED_UP: 'user-signed-up',
  USER_LOGGED_IN: 'user-logged-in',
  USER_LOGGED_OUT: 'user-logged-out',
  // Might not be required
  LMS_CONTENT_WATCHED: 'lms-content-watched',
  LMS_CONTENT_COMPLETED: 'lms-content-completed',
  ACTIVITY_STARTED: 'activity-started',
  ACTIVITY_CLOSED: 'activity-closed',
  ACTIVITY_COMPLETED: 'activity-completed',
  COMPETITION_LESSON_COMPLETED: 'competition-lesson-completed',
  ENROLLED_INTO_COMPETITION: 'enrolled-into-competition',
  COMPETITION_PLAY_COUNT_UPDATED: 'competition-play-count-updated',
  PIN_SET: 'pin-set',
  MULTIMEDIA_TOPIC_COMPLETED: "multimedia-topic-completed",
  ERROR_ENCOUNTERED:  "error-encountered",
  DETAILS_VIDEO_VIEWED: 'details-video-viewed',
};
export const checkpoint = (
  label: keyof typeof CheckpointName,
  properties: { [key: string]: string } = {}
) => {
  mixpanel.track(MixpanelEventName.CHECKPOINT, {
    label: CheckpointName[label],
    ...properties,
  });
};

export const mixpanelEvent = {
  pageViewed,
  buttonClicked,
  LinkOpened,
  bannerClicked,
  checkpoint,
};
