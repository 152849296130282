/* eslint-disable @typescript-eslint/naming-convention */
/** Basic topic fields */
interface TopicBase {
  duration: number;
  duration_completed: number;
  objective: string;
  prereq: string;
  rewards: number;
  status: 'complete' | 'enrolled';
  tag: any;
  topic_desc: any;
  topic_id: number;
  topic_name: string;
  topic_type: TOPIC_TYPE;
}
export interface AssignmentTopic extends TopicBase {
  topic_type: TOPIC_TYPE.ASSESSMENT;
  info: {
    info: {
      title: string;
      description: string;
    };
    choiceQuestion: {
      title: string;
      imgUrl: string;
      type: "CHECKBOX" | "RADIO";
      options: {
        value: string;
        imgUrl: string;
        choice_select: boolean;
        is_correct: boolean;
      }[];
    };
  }
}


/** Topic displaying a videp */
export interface VideoTopic extends TopicBase {
  topic_type: TOPIC_TYPE.VIDEO;
  info: {
    name_hi: string;
    name_lo: string;
    open_lab: boolean;
  };
}

/** Topic requiring an activity to be completed */
export interface ActivityTopic extends TopicBase {
  topic_type: TOPIC_TYPE.ACTIVITY;
  info: {
    folder: string;
    name: string;
  };
}

/** Topic in a lesson */
export type Topic = VideoTopic | ActivityTopic | AssignmentTopic;

/** Status of a topic */
export enum TOPIC_STATUS {
  CURRENT,
  DESELECTED,
  INACTIVE,
  CURRENT_COMPLETED,
  FINAL
}

/** Topic type */
export enum TOPIC_TYPE {
  ACTIVITY = 'block',
  VIDEO = 'video',
  ASSESSMENT = 'assessment',
}

/** Topic type checker */
export const isActivityTopic = (topic: Topic): topic is ActivityTopic =>
  topic.topic_type === TOPIC_TYPE.ACTIVITY;

/** Icon for topic type */
export const TOPIC_TYPE_ICON = {
  block: 'block_icon',
  video: 'video_icon',
  assessment: 'assessment_icon',
  editor: 'editor_icon'
};


/** Message related to a topic */
export interface ActivityTopicMessage {
  /** Name of topic */
  topic?: ActivityTopic['info']['name'];

  /** Number of points awarded */
  points?: number;

  /** Should the toolbar be shown */
  showToolbar?: boolean;
}
