import { Injectable } from '@angular/core';
import { Command } from './command.interface';
import { Topic } from '../models';
import { map } from 'rxjs/operators';
import { HourOfCodeService } from '../../public-api';
import { GetHocLesson } from './get-hoc-lesson.command';
export interface GetHocTopicOptions {
  hocId: number,
  forceRefresh?: boolean,
  index: number
}

@Injectable({
  providedIn: 'root'
})
export class GetHocTopic implements Command<GetHocTopicOptions, { index: number, topic: Topic }> {
  constructor( private hocService:HourOfCodeService,
    private getHocLesson:GetHocLesson
    ) {}

  execute(options: GetHocTopicOptions) {
    const { hocId, index,forceRefresh = false } = options;

    return this.getHocLesson.execute({hocId,forceRefresh}).pipe(
      map(lessons => {
        const topicIndex = index === -1 ? this.getFirstNotCompletedTopicIndex(lessons.topics) : index;

        return {
          index: topicIndex,
          topic: lessons.topics[topicIndex]
        };
      })
    );
  }

  private getFirstNotCompletedTopicIndex(topics: Topic[]) {
    const firstNotCompletedIndex = topics.findIndex(topic => topic.status === 'enrolled');
    return firstNotCompletedIndex === -1 ? 0 : firstNotCompletedIndex;
  }
}
