import { Injectable } from '@angular/core';
import { Command } from './command.interface';
import { Topic } from '../models';
import { map } from 'rxjs/operators';
import { GetLesson } from './get-lesson.command';

export interface GetTopicOptions {
  courseId: number,
  lessonId: number,
  forceRefresh?: boolean,
  index: number
}

@Injectable({
  providedIn: 'root'
})
export class GetTopic implements Command<GetTopicOptions, { index: number, topic: Topic }> {
  constructor(private getLesson: GetLesson) {}

  execute(options: GetTopicOptions) {
    const { courseId, lessonId, forceRefresh = false, index } = options;

    return this.getLesson.execute({ courseId, lessonId, forceRefresh }).pipe(
      map(lesson => {
        const topicIndex = index === -1 ? this.getFirstNotCompletedTopicIndex(lesson.topics) : index;

        return {
          index: topicIndex,
          topic: lesson.topics[topicIndex]
        };
      })
    );
  }

  private getFirstNotCompletedTopicIndex(topics: Topic[]) {
    const firstNotCompletedIndex = topics.findIndex(topic => topic.status === 'enrolled');
    return firstNotCompletedIndex === -1 ? 0 : firstNotCompletedIndex;
  }
}
