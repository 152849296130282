/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Lesson, Topic } from '../../models';
import { CapStorageService } from '../storage/cap-storage.service';

@Injectable({ providedIn: 'root' })
export class LessonService {
  /** Current lesson being viewed */
  _currentLesson!: Lesson;

  /** Completed topics */
  completedTopics: Set<number> = new Set();

  /** Totals lessons completed for current user */
  #lessons = new BehaviorSubject(0);

  constructor(private capStorageService: CapStorageService) {
    this.restoreCurrentLesson();
  }

  private async restoreCurrentLesson() {
    this._currentLesson = await this.capStorageService.getValue('currentLesson');
  }

  /** Totals lessons for current user */
  get lessons() {
    return this.#lessons.asObservable();
  }

  get currentLesson() {
    return this._currentLesson;
  }

  set currentLesson(newLesson) {
    this._currentLesson = newLesson;
    this.capStorageService.setValue('currentLesson', newLesson);
  }

  /** Current lesson Id */
  get currentLessonId() {
    return this.currentLesson.lesson_id;
  }

  /** Topics in the current lesson */
  currentTopics(): Topic[] {
    return this?.currentLesson?.topics ?? [];
  }

  /** Returns if the topic with the provided id has been previously completed */
  isTopicComplete(topicName: string) {
    const topic = this.currentLesson.topics.find(topic => topic.topic_name === topicName);
    return topic && topic.status === 'complete';
  }

  /**
   * Get lesson progress
   * TODO: make into a observable for better performance
   **/
  progressFor(lesson: Lesson) {
    const topicCount = lesson.topics.length;
    const topicsCompleted = lesson.topics.filter(({ status }) => status === 'complete').length;
    const progress = topicsCompleted / topicCount;
    return { topicCount, topicsCompleted, progress };
  }
}
