import { Topic } from './topic.interface';
/* eslint-disable @typescript-eslint/naming-convention */
/** Lesson in a course stage */
export interface Lesson {
  /** Number of minutes estimated to complete the lesson */
  duration: number;
  /** Minutes completed by the user */
  duration_completed: number;
  /** Lesson information */
  info: {
    name: string;
  };
  /** Description of the lesson */
  lesson_desc: string;
  /** Id */
  lesson_id: number;
  /** Is the lesson locked */
  lesson_lock: boolean;
  /** Name of the lesson */
  lesson_name: string;
  /** Lesson for teacher? */
  lesson_teacher: boolean;
  /** Topics in the lesson */
  topics: Topic[];
}


/** Status of lesson */
export enum LESSON_STATUS {
  DEFAULT = 'default-white',
  IN_PROGRESS = `Intersection`,
  LAST_ATTENDED = `lastattended`,
  COMPLETED = `completed`,
  LOCKED = 'locked',
  TEACHER_COMPLETED = 'completed',
}
