import { Command } from './command.interface';
import { Injectable } from '@angular/core';
import { IdmService } from '../services/idm/idm.service';
import { tap } from 'rxjs/operators';
import { CoursesService } from '../services/courses/courses.service';
import { PointsService } from '../services/courses/points.service';

@Injectable({
  providedIn: 'root'
})
export class SetPinOtpVerify implements Command<string, void> {
  constructor(
    private idmService: IdmService,
  ) {}

  execute(mobileOtp: string) {
    return this.idmService.setPinOtpVerify(mobileOtp)
  }
}
