import { Command } from './command.interface';
import { Injectable } from '@angular/core';
import { IdmService } from '../services/idm/idm.service';
import { tap } from 'rxjs/operators';
import { CoursesService } from '../services/courses/courses.service';
import { PointsService } from '../services/courses/points.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoginWithUrlToken implements Command<[string, string], void> {
  constructor(
    private idmService: IdmService,
    private coursesService: CoursesService,
    private pointsService: PointsService
  ) {}

  execute(params: [string, string]): Observable<void> {
    const [accessToken, mobileNum] = params;
    return this.idmService.loginWithUrlToken(accessToken, mobileNum).pipe(
      tap(() => {
        this.coursesService.refreshActiveCourses();
        this.coursesService.refreshActiveCoursesEnrollments();
        this.pointsService.refreshPoints();
      })
    );
  }
}
