import { Injectable, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { PIJAM_API_CLIENT_CONFIGURATION_TOKEN, Configuration } from '../interfaces/configuration';

@Injectable({
  providedIn: 'root'
})
export class ActivityIframeMessagingService {
  private _messages$ = new BehaviorSubject<any>(null);

  constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(PIJAM_API_CLIENT_CONFIGURATION_TOKEN) private configuration: Configuration
  ) {
    document.defaultView?.addEventListener('message', (event: MessageEvent) => {
      if (event.origin === configuration.topicActivityUrl) {
        this._messages$.next(event.data);
      }else if(event.origin.includes('avl.codemitra.org')){
        this._messages$.next(event.data);
      }
    });
  }

  get messages$() {
    return this._messages$.asObservable();
  }

  showInstructions(iframe: ElementRef<HTMLIFrameElement>) {
    iframe.nativeElement.contentWindow?.postMessage('showInstructions', "*");
  }
}
