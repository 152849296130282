import { Command } from './command.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LmsService } from '../services/courses/lms.service';
import { LMScourses } from '../models/lms.interface';
@Injectable({
  providedIn: 'root'
})
export class GetLmsList implements Command<void, LMScourses> {
  constructor(private lmsService: LmsService) {}

  execute(): Observable<LMScourses> {
    return this.lmsService.getLmsList();
  }
}
