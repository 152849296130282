<div class="video-title">{{ videos[currentVideoIndex]?.label }}</div>

<div id="carouselIndicators" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators" *ngIf="videos.length > 1">
    <li 
      *ngFor="let video of videos; let i = index"
      [class.active]="i === currentVideoIndex"
      (click)="onIndicatorClick(i)">
    </li>
  </ol>

  <div class="carousel-inner">
    <div *ngFor="let video of videos; let i = index" class="carousel-item" [class.active]="i === currentVideoIndex">
      <video class="step_video d-block w-100" controls [poster]="getAssetPath(video.poster)" (play)="videoViewMixpanelEvent()">
        <source [src]="video.url" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
  <a *ngIf="videos.length > 1" class="carousel-control-prev" role="button" (click)="onControlClick(false)">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a *ngIf="videos.length > 1" class="carousel-control-next" role="button" (click)="onControlClick(true)">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
