import { Injectable } from '@angular/core';
import { Command } from './command.interface';
import { PijamHttpClient } from '../http-client/pijam-http-client';
import { endpoints } from '../constants/endpoints';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetVideoUrlHoc implements Command<number, string> {
  constructor(private httpClient: PijamHttpClient) {}

  execute(topicId: number) {
    return this.httpClient.get<string>(`${endpoints.TOPIC_DOWNLOAD_URL_HOC}?topic_id=${topicId}&res=hi`).pipe(
      map(response => JSON.parse(response).signedUrl ?? '')
    );
  }
}