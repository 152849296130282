/* eslint-disable @typescript-eslint/naming-convention */

import { CourseStage } from './stage.interface';

export interface Course {
  course_id: number;
  course_img: string;
  course_name: string;
  created_at: string;
  stage_count: number;
}

export interface CourseTileData {
  id: number;
  status: string;
  image: string;
  name: string;
  short_desc: string;
}

export interface CourseBucketByStatus {
  course_count: number;
  courses: Course[];
  status: string;
}

/** Course for user to complete */
export interface CourseEnrollment {
  course_id: number;
  course_name: string;
  duration: number;
  duration_completed: number;
  long_desc: string;
  short_desc: string;
  stages: CourseStage[];
  topics:CourseStage[];
  status:string;
}
