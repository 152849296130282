import { Command } from './command.interface';
import { IdmService } from '../services/idm/idm.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Logout implements Command<void, void> {
  constructor(private idmService: IdmService) {}

  execute() {
    return this.idmService.logout();
  }
}
