import { Command } from './command.interface';
import { CoursesService } from '../services/courses/courses.service';
import { Injectable } from '@angular/core';
import { HocEnrollment } from '../models/HourOfCode.model';
import { Observable } from 'rxjs';
import { HourOfCodeService } from '../../public-api';

export interface GetHocTopicOptions {
  hocId: number,
  forceRefresh?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class GetHoc implements Command<GetHocTopicOptions, HocEnrollment> {
  hocId!: number;
  forceRefresh = false;

  constructor( private hocService:HourOfCodeService) {}

  execute(options: GetHocTopicOptions): Observable<HocEnrollment> {
    const { hocId,forceRefresh = false } = options;

    return this.hocService.getCourse(hocId,forceRefresh);
  }
}
