import { Injectable } from '@angular/core';
import { Command } from './command.interface';
import { CoursesService } from '../services/courses/courses.service';

@Injectable({
  providedIn: 'root'
})
export class GetCurrentTopicIndex implements Command<void, number> {
  constructor(private coursesService: CoursesService) {}

  execute() {
    return this.coursesService.currentTopicIndex;
  }
}
