import { Injectable } from '@angular/core';
import { Command } from './command.interface';
import { HourOfCodeService } from '../services/hoc/hour-of-code.service';

@Injectable({
  providedIn: 'root'
})
export class GetCurrentHocTopicIndex implements Command<void, number> {
  constructor(private hocService: HourOfCodeService) {}

  execute() {
    return this.hocService.currentTopicIndex;
  }
}
