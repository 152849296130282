import { Injectable } from '@angular/core';
import { Command } from './command.interface';
import { HourOfCodeService } from '../services/hoc/hour-of-code.service';
import { GetCurrentHocTopicIndex } from './get-current-hoc-topic-index.command';

@Injectable({
  providedIn: 'root'
})
export class NextHocTopic implements Command<void, void> {
  constructor(private hocService: HourOfCodeService, private getCurrentHocTopicIndex: GetCurrentHocTopicIndex) {}

  execute() {
    this.getCurrentHocTopicIndex.execute().subscribe(currentIndex => {
      this.hocService.updateCurrentTopicIndex(currentIndex + 1);
    });
  }
}
