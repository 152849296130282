import { Injectable } from '@angular/core';
import { CapStorageService } from '../storage/cap-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RewardService {
  private rewards: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

  constructor(private capStorage: CapStorageService) {
    this.initRewards();
  }

  private async initRewards() {
    try {
      // Retrieve the initial value from storage or set a default value
      const storedValue = await this.capStorage.getValue('reward_points');
      const initialRewards = typeof storedValue === 'number' ? storedValue : 0;
      this.rewards.next(initialRewards);
    } catch (error) {
      console.error('Error initializing rewards:', error);
    }
  }

  async setRewards(value: number) {
    try {
      await this.capStorage.setValue('reward_points', value);
      this.rewards.next(value);
      this.updateLocalStorage(value)
    } catch (error) {
      console.error('Error setting rewards:', error);
    }
  }

  getRewards(): Observable<number | null> {
    return this.rewards.asObservable();
  }
  async updateLocalStorage(updatedValue: null | number) {
    const storedValue = await this.capStorage.getValue('user_profile');
    
  
    if (typeof storedValue === 'object' && storedValue !== null) {
  
        const userProfile = storedValue as {
            first_name: string;
            last_name: string;
            user_type: string;
            mobile_num: string;
            rewards_gained: null | number;
            notification: number;
        };

       
        userProfile.rewards_gained = updatedValue;

        
        await this.capStorage.setValue('user_profile', userProfile);
    } else {
       
        console.error('Stored value is not an object.');
    }
}


}
