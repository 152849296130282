import { Command } from './command.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LmsService } from '../services/courses/lms.service';
import { LMScourse } from '../models/lms.interface';
@Injectable({
    providedIn: 'root'
  })
  export class GetLmscourse implements Command<number, LMScourse> {
    constructor(private lmsService: LmsService) {}
  
    execute(courseId: number): Observable<LMScourse> {
      return this.lmsService.getLmscourseData(courseId);
    }
  }
