import { Injectable } from '@angular/core';
import { of, BehaviorSubject, Subject } from 'rxjs';
import { CountCondition } from './conditions/count.condition';
import { CapStorageService } from '../storage/cap-storage.service';

export interface ProgressInfo {
  lessonsDone?: number;
  tasksDone?: number;
  timeUsed?: number;
  tipsUsed?: number;
}

export interface BadgeCondition {
  fulfilled(progressInfo: ProgressInfo): boolean;
}

export interface Badge {
  name: string;
  timesEarned: number;
  type: string;
  icon: string;
}

class BadgeAlgorithm {
  constructor(
    private progressInfo: BehaviorSubject<ProgressInfo>,
    private _badgeType: string,
    private conditions: BadgeCondition[]
  ) { }

  get badgeType() {
    return this._badgeType;
  }

  shouldAwardBadge() {
    return this.conditions.reduce(
      (shouldAward, condition) => shouldAward = shouldAward && condition.fulfilled(this.progressInfo.value),
      true
    );
  }
}

const AVAILABLE_BADGES_KEY = 'badges';
const LESSON_PROGRESS_KEY = 'lessonProgressInfo';
const LIFETIME_PROGRESS_KEY = 'lifetimeProgressInfo';
const SESSION_PROGRESS_KEY = 'sessionProgressInfo';
const TASK_PROGRESS_KEY = 'taskProgressInfo';

const DEFAULT_LIFETIME_PROGRESS = {
  lessonsDone: 0,
  tasksDone: 0,
  tipsUsed: 0
};

const DEFAULT_SESSION_PROGRESS = {
  lessonsDone: 0,
  tasksDone: 0,
  timeUsed: 0,
  tipsUsed: 0
};

const DEFAULT_TASK_PROGRESS = {
  timeUsed: 0,
  tipsUsed: 0
};

const DEFAULT_LESSON_PROGRESS = {
  tasksDone: 0,
  timeUsed: 0,
  tipsUsed: 0
};

@Injectable({
  providedIn: 'root'
})
export class BadgesService {
  private algorithms: BadgeAlgorithm[] = [];
  private availableBadges: Badge[] = [];
  private lessonProgress$ = new BehaviorSubject<ProgressInfo>(DEFAULT_LESSON_PROGRESS);
  private lifetimeProgress$ = new BehaviorSubject<ProgressInfo>(DEFAULT_LIFETIME_PROGRESS);
  private sessionProgress$ = new BehaviorSubject<ProgressInfo>(DEFAULT_SESSION_PROGRESS);
  private taskProgress$ = new BehaviorSubject<ProgressInfo>(DEFAULT_TASK_PROGRESS);
  private initDone: Promise<boolean>;
  private newBadges$ = new Subject<Badge[]>();

  constructor(
    private capStorage: CapStorageService
  ) {
    this.initDone = this.init();
  }

  get newBadges() {
    return this.newBadges$.asObservable();
  }

  checkForNewBadge() {
    const currentBadges = this.getCurrentBadges().map(badge => badge.type);

    const newBadges = this.algorithms.reduce<Badge[]>(
      (newBadgeAlerts, algorithm) => {
        if (algorithm.shouldAwardBadge() && currentBadges.indexOf(algorithm.badgeType) === -1) {
          this.availableBadges = this.availableBadges.map(badge => {
            if (badge.type === algorithm.badgeType) {
              badge.timesEarned += 1;

              newBadgeAlerts.push(badge);
            }

            return badge;
          });

          this.capStorage.setValue(AVAILABLE_BADGES_KEY, this.availableBadges);
        }

        return newBadgeAlerts;
      },
      []
    );
    this.newBadges$.next(newBadges);
  }

  getBadges() {
    return of(this.getCurrentBadges());
  }

  async lessonCompleted() {
    await this.updateLifetimeProgress({ lessonsDone: (this.lifetimeProgress$.value?.lessonsDone ?? 0) + 1 });
    return this.updateSessionProgress({ lessonsDone: (this.sessionProgress$.value?.lessonsDone ?? 0) + 1 });
  }

  async resetLessonProgress() {
    await this.initDone;

    return this.updateProgress(LESSON_PROGRESS_KEY, DEFAULT_LESSON_PROGRESS, this.lessonProgress$);
  }

  async resetLifetimeProgress() {
    await this.initDone;

    return this.updateProgress(LIFETIME_PROGRESS_KEY, DEFAULT_LIFETIME_PROGRESS, this.lifetimeProgress$);
  }

  async resetSessionProgress() {
    await this.initDone;

    return this.updateProgress(SESSION_PROGRESS_KEY, DEFAULT_SESSION_PROGRESS, this.sessionProgress$);
  }

  async resetTaskProgress() {
    await this.initDone;

    return this.updateProgress(TASK_PROGRESS_KEY, DEFAULT_TASK_PROGRESS, this.taskProgress$);
  }

  async taskCompleted(timeUsed: number, tipsUsed: number) {
    await this.updateLifetimeProgress({
      tasksDone: (this.lifetimeProgress$.value?.tasksDone ?? 0) + 1,
      tipsUsed: (this.lifetimeProgress$.value?.tipsUsed ?? 0) + tipsUsed
    });
    await this.updateSessionProgress({
      tasksDone: (this.sessionProgress$.value?.tasksDone ?? 0) + 1,
      tipsUsed: (this.lifetimeProgress$.value?.tipsUsed ?? 0) + tipsUsed,
      timeUsed: (this.lifetimeProgress$.value?.timeUsed ?? 0) + timeUsed
    });
    await this.updateLessonProgress({
      tasksDone: (this.lessonProgress$.value?.tasksDone ?? 0) + 1,
      tipsUsed: (this.lifetimeProgress$.value?.tipsUsed ?? 0) + tipsUsed,
      timeUsed: (this.lifetimeProgress$.value?.timeUsed ?? 0) + timeUsed
    });
    return this.updateTaskProgress({
      timeUsed, tipsUsed
    });
  }

  async updateLessonProgress(newLessonProgress: ProgressInfo) {
    return this.updateProgress(LESSON_PROGRESS_KEY, newLessonProgress, this.lessonProgress$);
  }

  async updateLifetimeProgress(newLifetimeProgress: ProgressInfo) {
    return this.updateProgress(LIFETIME_PROGRESS_KEY, newLifetimeProgress, this.lifetimeProgress$);
  }

  async updateSessionProgress(newSessionProgress: ProgressInfo) {
    return this.updateProgress(SESSION_PROGRESS_KEY, newSessionProgress, this.sessionProgress$);
  }

  async updateTaskProgress(newTaskProgress: ProgressInfo) {
    return this.updateProgress(TASK_PROGRESS_KEY, newTaskProgress, this.taskProgress$);
  }

  private getCurrentBadges() {
    return this.availableBadges.filter(badge => badge.timesEarned > 0)
  }

  private async init() {
    const lifetimeProgress = await this.capStorage.getValue(LIFETIME_PROGRESS_KEY, DEFAULT_LIFETIME_PROGRESS);
    this.lifetimeProgress$.next(lifetimeProgress);

    const sessionProgress = await this.capStorage.getValue(SESSION_PROGRESS_KEY, DEFAULT_SESSION_PROGRESS);
    this.sessionProgress$.next(sessionProgress);

    const taskProgress = await this.capStorage.getValue(TASK_PROGRESS_KEY, DEFAULT_TASK_PROGRESS);
    this.taskProgress$.next(taskProgress);

    const lessonProgress = await this.capStorage.getValue(LESSON_PROGRESS_KEY, DEFAULT_LESSON_PROGRESS);
    this.lessonProgress$.next(lessonProgress);

    this.availableBadges = await this.capStorage.getValue(AVAILABLE_BADGES_KEY, [{
      name: 'Little Einstein',
      timesEarned: 0,
      type: 'einstein',
      icon: 'einstein.svg'
    }, {
      name: 'Marathon runner',
      timesEarned: 0,
      type: 'marathon',
      icon: 'marathon.svg'
    }, {
      name: 'Speedy bee',
      timesEarned: 0,
      type: 'speedy',
      icon: 'speedy.svg'
    }]);

    this.algorithms = [
      new BadgeAlgorithm(
        this.lifetimeProgress$,
        'einstein',
        [
          new CountCondition(5, 'tasksDone'),
          new CountCondition(0, 'tipsUsed')
        ]
      ),
      new BadgeAlgorithm(
        this.sessionProgress$,
        'marathon',
        [
          new CountCondition(3, 'lessonsDone'),
        ]
      ),
      new BadgeAlgorithm(
        this.taskProgress$,
        'speedy',
        [
          new CountCondition(0, 'timeUsed', 'gt'),
          new CountCondition(30, 'timeUsed', 'lte')
        ]
      ),
    ];

    return true;
  }

  private async updateProgress(key: string, newValue: ProgressInfo, subject: BehaviorSubject<ProgressInfo>) {
    const updatedValue = { ...subject.value, ...newValue };

    await this.capStorage.setValue(key, updatedValue);
    subject.next(updatedValue);
  }
}
