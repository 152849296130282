import { Injectable, Inject } from '@angular/core';
import { Command } from './command.interface';
import { PIJAM_API_CLIENT_CONFIGURATION_TOKEN, Configuration } from '../interfaces/configuration';
import { ActivityTopic } from '../models';

@Injectable({
  providedIn: 'root'
})
export class GetActivityUrl implements Command<ActivityTopic, string> {
  constructor(@Inject(PIJAM_API_CLIENT_CONFIGURATION_TOKEN) private configuration: Configuration) {}

  execute(topic: ActivityTopic) {
    return `${this.configuration.topicActivityUrl}/${topic.info.folder}/${topic.info.name}.html`;
  }
}
