import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { StepVideo } from 'ngx-pijam-api-client';
import { getAssetPath } from 'src/Utility/path-util';
import { mixpanelEvent } from '../pi.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-video-carousel',
  templateUrl: './video-carousel.component.html',
  styleUrls: ['./video-carousel.component.scss']
})
export class VideoCarouselComponent implements AfterViewInit {
  @Input() videos: StepVideo[] = [];
  currentVideoIndex = 0;
  competitionId!: number;
  getAssetPath = getAssetPath;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngAfterViewInit(): void {

    this.getCompetitionId();

    const carouselElement = document.getElementById('carouselIndicators');
    if (carouselElement) {
      carouselElement.addEventListener('slid.bs.carousel', () => {
        this.updateVideoState();
      });
    }
  }

  getCompetitionId(): void {
    this.route.params.subscribe((params) => {
      this.competitionId = +params['id'];
    });
  }

  updateVideoState(): void {
    this.pauseAllVideos();
    this.updateVideoLabel();
    this.videoViewMixpanelEvent();
    this.cdr.detectChanges();
  }

  onIndicatorClick(index: number): void {
    this.currentVideoIndex = index;
    this.updateVideoState();
  }

  onControlClick(isNext: boolean): void {
    this.currentVideoIndex = isNext
      ? (this.currentVideoIndex + 1) % this.videos.length
      : (this.currentVideoIndex - 1 + this.videos.length) % this.videos.length;

    this.updateVideoState();
  }

  pauseAllVideos(): void {
    const carouselElement = document.getElementById('carouselIndicators');
    const allVideos = carouselElement?.querySelectorAll('video') || [];
    allVideos.forEach((video: HTMLVideoElement) => video.pause());
  }

  updateVideoLabel(): void {
    const videoLabelElement = document.querySelector('.video-title') as HTMLElement;
    if (videoLabelElement) {
      videoLabelElement.innerHTML = this.videos[this.currentVideoIndex]?.label || '';
      videoLabelElement.classList.remove('fade-in');
      void videoLabelElement.offsetWidth;
      videoLabelElement.classList.add('fade-in');
    }
  }

  videoViewMixpanelEvent(): void {
    const currentVideo = this.videos[this.currentVideoIndex];
    mixpanelEvent.checkpoint('DETAILS_VIDEO_VIEWED', {
      video_url: currentVideo.url,
      video_title: currentVideo.label,
      competitionId: this.competitionId.toString(),
    });
  }

}
