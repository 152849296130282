import { Injectable } from '@angular/core';
import { Command } from './command.interface';
import { CoursesService } from '../services/courses/courses.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateCurrentTopicIndex  {
  constructor(private coursesService: CoursesService) {}

  execute(newIndex: number): void {
    this.coursesService.updateCurrentTopicIndex(newIndex);
  }
  
}
