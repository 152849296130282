import { Injectable } from '@angular/core';
import { Command } from './command.interface';
import { IdmService } from '../services/idm/idm.service';

export interface RegisterData {
  first_name?: string;
  last_name?: string;
  email?: string;
  mobile_num: string;
  grade: string;
  gender?: string;
  access_level?: string;
  location: string;
  udise_no?: string;
  username: string;
  user_type: string;
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class Register implements Command<any, void> {
  constructor(private idmService: IdmService) {}

  execute(userData: RegisterData) {
    return this.idmService.register(userData);
  }
}
