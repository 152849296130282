import { BadgeCondition, ProgressInfo } from './../badges.service';

type PropType = 'lessonsDone' | 'tasksDone' | 'timeUsed' | 'tipsUsed';

export class CountCondition implements BadgeCondition {
  constructor(private count: number, private prop: PropType, private op: 'eq' | 'gte' | 'gt' | 'lt' | 'lte' = 'eq') { }

  fulfilled(progress: ProgressInfo) {
    let fulfilled = false;
    const prop = progress[this.prop] || 0;
    switch (this.op) {
      case 'eq':
        fulfilled = prop === this.count;
        break;
      case 'gt':
        fulfilled = prop > this.count;
        break;
      case 'gte':
        fulfilled = prop >= this.count;
        break;
      case 'lt':
        fulfilled = prop < this.count;
        break;
      case 'lte':
        fulfilled = prop <= this.count;
        break;
    }

    return fulfilled;
  }
}
