import { Injectable, ElementRef } from '@angular/core';
import { Command } from './command.interface';
import { ActivityIframeMessagingService } from '../services/activity-iframe-messaging.service';

@Injectable({
  providedIn: 'root'
})
export class ShowInstructions implements Command<ElementRef<HTMLIFrameElement>> {
  constructor(private activityIframeMessagingService: ActivityIframeMessagingService) {}

  execute(iframe: ElementRef<HTMLIFrameElement>) {
    this.activityIframeMessagingService.showInstructions(iframe);
  }
}
