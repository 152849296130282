import { InjectionToken } from '@angular/core';

export interface Configuration {
  apiBaseUrl: string;
  requestOtpUrl: string;
  storeVersionUrl: string;
  topicActivityUrl: string;
}

export const PIJAM_API_CLIENT_CONFIGURATION_TOKEN = new InjectionToken('PIJAM_API_CLIENT_CONFIGURATION_TOKEN');
