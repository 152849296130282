/* eslint-disable @typescript-eslint/naming-convention */

import { Lesson } from './lesson.interface';

/** Stage in a course */
export interface CourseStage {
  duration: number;
  duration_completed: number;
  lessons: Lesson[];
  stage_id: number;
  stage_name: string;
  status:string;
}
