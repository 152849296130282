import { Injectable } from '@angular/core';
import { Command } from './command.interface';
import { CoursesService } from '../services/courses/courses.service';

@Injectable({
  providedIn: 'root'
})
export class MarkTopicComplete implements Command<number, void> {
  constructor(private coursesService: CoursesService) {}

  execute(topicId: number) {
    return this.coursesService.setTopicComplete(topicId);
  }
}
