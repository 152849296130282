import { Command } from './command.interface';
import { Injectable } from '@angular/core';
import { IdmService } from '../services/idm/idm.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPin implements Command<{ mobileOtp: string, pin: string }, void> {
  constructor(private idmService: IdmService) {}

  execute(params: { mobileOtp: string, pin: string }) {
    const { mobileOtp, pin } = params;
    return this.idmService.resetPin(mobileOtp, pin);
  }
}
