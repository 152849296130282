import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
// import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  isLoading = false;
  private config = {} as any;

  constructor(
    private router: Router,
    private http: HttpClient,
    // private iab: InAppBrowser,
    // private screenOrientation: ScreenOrientation
  ) { }

  /** Get the current username */
  get username() {
    return encodeURIComponent(this.getConfig('mobile_num'));
  }

  doNavigate(url: string, params?: any): void {
    if (typeof url === 'string') {
      this.router.navigate([url], { queryParams: params });
    }
  }

  getComposedHeaders(customHeaders: any) {
    let headers = new HttpHeaders();

    if (customHeaders) {
      for (const [headerKey, headerVal] of customHeaders) {
        if (typeof headerKey === 'string' && typeof headerVal === 'string') {
          headers = headers.append(headerKey, headerVal);
        }
      }
    }
    return headers;
  }

  doHttpPostCall(url: string, input: any, { customHeaders = null } = {}): Observable<any> {
    return this.http.post(url, input, { headers: this.getComposedHeaders(customHeaders) });
  }

  doHttpGetCall<T>(url: string, { customHeaders = null } = {}): Observable<T> {
    return this.http.get<T>(url, { headers: this.getComposedHeaders(customHeaders) });
  }

  doHttpPutCall(url: string, input: any, { customHeaders = null } = {}): Observable<any> {
    return this.http.put(url, input, { headers: this.getComposedHeaders(customHeaders) });
  }

  // doLoadBrowserUrl(url: string): void {
  //   const scratchBrowserInstance = this.iab.create(url, '_blank', { location: 'no', hidden: 'yes', zoom: 'no' });
  //   scratchBrowserInstance.on('loadstart').subscribe(e => {
  //     // set to landscape
  //     this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
  //     this.isLoading = true;
  //   });

  //   scratchBrowserInstance.on('loadstop').subscribe(e => {
  //     this.isLoading = false;
  //     scratchBrowserInstance.show();
  //   });

  //   scratchBrowserInstance.on('exit').subscribe(e => {
  //     this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
  //     scratchBrowserInstance.close();
  //   });
  // }

  getConfig(key: any) {
    if (key in this.config) {
      return this.config[key];
    }
    return null;
  }

  setConfig(key: any, value: any) {
    this.config[key] = value;
  }
}
