/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Inject } from '@angular/core';
import { PijamHttpClient } from '../../http-client/pijam-http-client';
import { CapStorageService } from '../storage/cap-storage.service';
import { endpoints } from '../../constants/endpoints';
import {
  PIJAM_API_CLIENT_CONFIGURATION_TOKEN,
  Configuration,
} from '../../interfaces/configuration';
import { tap, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { RegisterData } from '../../commands';
import { RewardService } from '../courses/rewards.service';
import { UserProfileResponse } from '../../models/idm.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IdmService {
  userProfileDetails?: UserProfileResponse;
  userSession: any;
  private referrerPath: string | null = null;

  constructor(
    private pijamHttpClient: PijamHttpClient,
    private capStorage: CapStorageService,
    private rewardService: RewardService,
    @Inject(PIJAM_API_CLIENT_CONFIGURATION_TOKEN)
    private configuration: Configuration
  ) {}

  setUserProfileDetails() {
    // Call User Profile API
    const getUserProfile = this.pijamHttpClient.get<any>(
      endpoints.PROFILE + '?username=' + encodeURIComponent(this.username)
    );

    return getUserProfile.pipe(
      tap((data: any) => {
        this.userProfileDetails = data as UserProfileResponse;
        this.capStorage.setValue('user_profile', data);
        // Set the rewards in the RewardService
        if (data && data.rewards_gained !== undefined) {
          this.rewardService.setRewards(data.rewards_gained);
        }
      })
    );
  }

  get(userKey: string) {
    if (!userKey) {
      throw 'Please provide a key value to fetch';
    }
    if (this.userProfileDetails !== undefined) {
      if (userKey in this.userProfileDetails) {
        return this.userProfileDetails[userKey as keyof UserProfileResponse];
      }
    }
    return null;
  }

  get accessToken() {
    return this.userSession?.access_token;
  }

  get refreshToken() {
    return this.userSession.refresh_token;
  }

  get username() {
    return this.userSession.mobile_num;
  }

  async isLoggedInUser(): Promise<boolean> {
    let loggedIn = false;

    const access_token = await this.capStorage.getValue<string>('access_token');
    if (access_token) {
      const refresh_token = await this.capStorage.getValue<string>(
        'refresh_token'
      );
      const mobile_num = await this.capStorage.getValue<string>('mobile_num');
      this.userSession = { access_token, refresh_token, mobile_num };
      this.userProfileDetails = await this.capStorage.getValue('user_profile');

      loggedIn = true;
    }

    return loggedIn;
  }

  register(userData: RegisterData) {
    return this.pijamHttpClient
      .post(endpoints.SIGNUP, { ...userData, created_by: 'web_app' })
      .pipe(
        switchMap((response) => {
          if (
            response.result &&
            (response.result.indexOf('Duplicate') > -1 ||
              response.result.indexOf('error') > -1)
          ) {
            return throwError(response.result);
          } else {
            const { access_token, refresh_token } = response.token;
            this.setInfo({ access_token, refresh_token, mobile_num: userData.mobile_num });
            return this.setUserProfileDetails();
      }
        })
      );
  }

  requestOtp(username: string) {
    return this.pijamHttpClient
      .post(endpoints.SEND_OTP, { phone: username })
      .pipe(tap((_) => (this.userSession = { mobile_num: username })));
  }
  

requestOtpForPin(username: string) {
  return this.pijamHttpClient
  .post(endpoints.SEND_OTP, { phone: username })
  }
  
  setUserSession(username: string) {
    this.userSession = { mobile_num: username };
  }

  loginWithPin(username: string, pin: string) {
    return this.pijamHttpClient
      .post(endpoints.LOGIN_PIN, {
        username,
        pin,
      })
      .pipe(
        tap((resp: { access_token: string; refresh_token: string }) => {
          this.setInfo({ ...resp, mobile_num: username });
        }),
        switchMap(() => this.setUserProfileDetails())
      );
  }

  setPin(pin: string) {
    return this.pijamHttpClient.post(endpoints.SET_PIN, {
      username: this.username,
      pin,
    });
  }

  login(mobile_otp: string) {
    return this.pijamHttpClient
      .post(endpoints.LOGIN_OTP, {
        username: this.username,
        mobile_otp,
        user_type: this.capStorage.getValue<string>('user_type'),
      })
      .pipe(
        tap((resp: { access_token: string; refresh_token: string }) => {
          this.setInfo({ ...resp, mobile_num: this.username });
        }),
        switchMap(() => this.setUserProfileDetails())
      );
  }

  resetPin(mobile_otp: string,pin: string) {
    return this.pijamHttpClient.post(endpoints.RESET_PIN, { 
      mobile_otp: mobile_otp,
      pin
    });
  }
  

  loginWithUrlToken(accessToken: string, mobileNum: string) {
    const resp = { access_token: accessToken,mobile_num: mobileNum };
    this.updateUserSession({ ...resp });
    return this.setUserProfileDetails();
  }
  
  setPinOtpVerify(mobile_otp: string) {
    return this.pijamHttpClient.post(endpoints.LOGIN_OTP, {
      username: this.username,
      mobile_otp,
      user_type: this.capStorage.getValue<string>('user_type'),
    });
  }
  setInfo({
    access_token,
    refresh_token,
    mobile_num,
  }: {
    access_token: string;
    refresh_token: string;
    mobile_num: string;
  }) {
    this.userSession = { access_token, refresh_token, mobile_num };
    console.log(this.userSession);
    this.capStorage.setValue<string>('access_token', access_token);
    this.capStorage.setValue<string>('refresh_token', refresh_token);
    this.capStorage.setValue<string>('mobile_num', mobile_num);
  }

  updateUserSession({
    access_token,
    mobile_num,
  }: {
    access_token: string;
    mobile_num: string;
  }) {
    this.userSession = { access_token, mobile_num };
    this.capStorage.setValue<string>('access_token', access_token);
    this.capStorage.setValue<string>('mobile_num', mobile_num);
  }
  
  async resetInfo() {
    this.userSession = {};
    await this.capStorage.clear();
  }

  isNewLogin() {
    return this.capStorage.getValue<boolean>('isNewLogin', false);
  }

  async logout() {
    await this.resetInfo();
  }

  setReferrer(path: string | null) {
    this.referrerPath = path;
  }
  
  getReferrer(): string | null {
    return this.referrerPath;
  }
}
