import { Injectable } from "@angular/core";
import { PijamHttpClient } from "../../http-client/pijam-http-client";
import { endpoints } from "../../constants/endpoints"; 
import { LMScourses,LMScourse } from "../../models/lms.interface";
@Injectable({
  providedIn: 'root'
})
export class LmsService {

  constructor(
    private pijamHttpClient: PijamHttpClient,
  ) {
    
   }

  getLmsList() {
    return this.pijamHttpClient.get<LMScourses>(endpoints.LMS_COURSE);
  }

  getLmscourseData(course_id: number) {
    let endPoint = endpoints.LMS_COURSE_CONTENT + course_id + "/content";
    return this.pijamHttpClient.get<LMScourse>(endPoint);
  }
}
