import { Injectable } from '@angular/core';
import { Command } from './command.interface';
import { Lesson } from '../models';
import { GetCourse } from './get-course.command';
import { map, tap } from 'rxjs/operators';
import { BadgesService } from '../services/courses/badges.service';

export interface GetLessonOptions {
  courseId: number,
  lessonId: number,
  forceRefresh?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class GetLesson implements Command<GetLessonOptions, Lesson> {
  constructor(private getCourse: GetCourse, private badgesService: BadgesService) {}

  execute(options: GetLessonOptions) {
    const { courseId, lessonId, forceRefresh = false } = options;

    return this.getCourse.execute({ courseId, forceRefresh }).pipe(
      map(course => course.stages.reduce((lesson, stage) => {
        const foundLesson = stage.lessons.find(lesson => lesson.lesson_id === lessonId);
        if (foundLesson) {
          return foundLesson;
        } else {
          return lesson;
        }
      }, null as unknown as Lesson)),
      tap(() => this.badgesService.resetLessonProgress())
    );
  }
}
