import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class CapStorageService {

  /** Store a value in the storage */
  public async setValue<T>(key: string, val: T) {
    const value = JSON.stringify(val);
    await Preferences.set({ key, value });
  }

  /** Retrieve a value from storage */
  public async getValue<T>(key: string, defaultValue: T | null = null): Promise<T> {
    const { value } = await Preferences.get({ key });
    return ((value && JSON.parse(value)) || defaultValue) as T;
  }

  /** Remvoes a value from storage */
  public async removeValue(key: string) {
    await Preferences.remove({ key });
  }

  public async clear() {
    await Preferences.clear();
  }
}
