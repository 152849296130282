export const endpoints = {
  CLIENT_DETAILS: 'v4/client_competitions',
  LOGIN_PIN: 'v4/User/LoginPin',
  SET_PIN: 'v4/User/SetPin',
  SIGNUP: 'v4/User/SignUp',
  GENERATE_OTP: 'lambda/smsotp-service-dev-generate_otp',
  SEND_OTP: 'v4/User/SendOtp',
  LOGIN_OTP: 'v4/User/LoginOtp',
  RESET_PIN: 'v4/User/ResetPin',
  PROFILE: 'v4/User/Profile',
  GET_ENROLL_DETAIL: 'v4/Course/GetEnrollDetail',
  REFRESH_TOKENS: 'v4/User/RefreshTokens',
  SIGNED_URL: 'v4/User/signedUrl',
  TOPIC_DOWNLOAD_URL: 'v4/Course/TopicDownloadUrl',
  UPDATE_TOPIC_STATUS: 'v4/Course/EnrollTopicStatus',
  ENROLL_COURSE: 'v4/Course/EnrollCourse',
  LIST_COURSES_BY_STATUS: 'v4/Course/ListByStatus',
  GET_ENROLL_DETAIL_HOC: 'v4/Hoc/GetEnrollDetail',
  GET_ALL_HOC: 'v4/Hoc/GetAll',
  ENROLL_HOC: 'v4/Hoc/EnrollHoc',
  TOPIC_DOWNLOAD_URL_HOC: 'v4/Hoc/TopicDownloadUrl',
  UPDATE_TOPIC_STATUS_HOC: 'v4/Hoc/EnrollTopicStatus',
  LMS_COURSE: 'v4/lms/courses',
  LMS_COURSE_CONTENT: `v4/lms/courses/`,
  GET_COMPETITION_LIST: 'v4/competitions',
  GET_COMPETITION_BY_ID: 'v4/competitions/:competition_id',
  GET_COMPETITION_LESSONS: 'v4/competitions/:competition_id/lessons',
  GET_COMPETITION_TOPICS:
    'v4/competitions/:competition_id/lessons/:lesson_id/topics',
  GET_COMPETITION_ANALYTICS: 'v4/competitions/:competition_id/analytics',
  GET_ENROLLMENT_STATUS: 'v4/competitions/:competition_id/enrollment_status',
  ENROLL_COMPETITION: 'v4/competitions/:competition_id/enroll',
  GET_COMPETITION_SCORECARD: 'v4/competitions/:competition_id/scorecard',
  UPDATE_COMPETITION_TOPIC_STATUS: 'v4/competitions/:competition_id/lessons/:lesson_id/topics/:topic_id/action',
  GET_COMPETITION_FEEDBACK_STATUS: 'v4/competitions/:competition_id/feedback_status',
  SET_COMPETITION_FEEDBACK: 'v4/competitions/:competition_id/feedback',
  GET_COMPETITION_COMPLETION_STATUS: 'v4/competitions/:competition_id/completion_status',
  SET_CERTIFICATE_DOWNLOAD_STATUS: 'v4/competitions/:competition_id/certificate_download_status',
  GET_TOPIC_RESPONSE: 'v4/competitions/:competition_id/lessons/:lesson_id/topics/:topic_id/topic_response',
  GET_COMPETITION_DETAILS:'v4/competitions/:competition_id/details_meta' 
};
