import { Command } from './command.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RewardService } from '../services/courses/rewards.service'; 

@Injectable({
  providedIn: 'root'
})
export class GetRewards implements Command<void, number | null> {
  constructor(private rewardService: RewardService) {}

  execute(): Observable<number | null> {
    return this.rewardService.getRewards();
  }
}
