import { Command } from './command.interface';
import { Injectable } from '@angular/core';
import { IdmService } from '../services/idm/idm.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SetPinCommand implements Command<string, void> {
  constructor(private idmService: IdmService) {}

  execute(mobileOtp: string) {
    return this.idmService.setPin(mobileOtp).pipe(
      tap(() => {
        // Additional actions after setting the PIN 
        
      })
    );
  }
}
