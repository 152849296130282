import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PijamHttpClient {
  constructor(private httpClient: HttpClient) {}

  get<T>(url: string, { customHeaders = new Map() } = {}): Observable<T> {
    return this.httpClient.get<T>(url, {
      headers: this.getComposedHeaders(customHeaders),
    });
  }

  post(
    url: string,
    input: any,
    { customHeaders = new Map() } = {}
  ): Observable<any> {
    return this.httpClient.post(url, input, {
      headers: this.getComposedHeaders(customHeaders),
    });
  }

  put(
    url: string,
    input: any,
    { customHeaders = new Map() } = {}
  ): Observable<any> {
    return this.httpClient.put(url, input, {
      headers: this.getComposedHeaders(customHeaders),
    });
  }

  private getComposedHeaders(customHeaders: Map<string, string>) {
    let headers = new HttpHeaders();

    for (const [headerKey, headerVal] of customHeaders) {
      headers = headers.append(headerKey, headerVal);
    }

    return headers;
  }
}
