import { Injectable } from '@angular/core';
import { PijamHttpClient } from '../../http-client/pijam-http-client';
import { endpoints } from '../../constants/endpoints';
import {
  CompetitionAnalyticsResponse,
  CompetitionDetailsResponse,
  CompetitionEnrollmentStatusResponse,
  CompetitionLessonTopicsResponse,
  CompetitionLessonsResponse,
  CompetitionScoreCardResponse,
  CompetitionsListResponse,
  CompetitionTopicUpdateResponse,
  CompetitionStatusResponse,
  FeedbackData,
  DetailsStructureResponse
} from '../../models/competition.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompetitionService {
  constructor(private pijamHttpClient: PijamHttpClient) { }

  getCompetitions() {
    return this.pijamHttpClient.get<CompetitionsListResponse>(
      endpoints.GET_COMPETITION_LIST
    );
  }

  getCompetitionDetails(competition_id: number) {
    const endPoint = `${endpoints.GET_COMPETITION_BY_ID.replace(
      ':competition_id',
      competition_id.toString()
    )}`;
    return this.pijamHttpClient.get<CompetitionDetailsResponse>(endPoint);
  }

  getCompetitionLessons(competition_id: number, locale?: string) {
    let endPoint = `${endpoints.GET_COMPETITION_LESSONS.replace(
      ':competition_id',
      competition_id.toString()
    )}`;
    if (locale) {
      endPoint += `?locale=${locale}`;
    }
    return this.pijamHttpClient.get<CompetitionLessonsResponse>(endPoint);
  }

  getCompetitionTopics(competition_id: number, lesson_id: number, locale?: string) {
    let endPoint = `${endpoints.GET_COMPETITION_TOPICS.replace(
      ':competition_id',
      competition_id.toString()
    ).replace(':lesson_id', lesson_id.toString())}`;
    if (locale) {
      endPoint += `?locale=${locale}`;
    }
    return this.pijamHttpClient.get<CompetitionLessonTopicsResponse>(endPoint);
  }

  getCompetitionAnalytics(competition_id: number) {
    const endpoint = `${endpoints.GET_COMPETITION_ANALYTICS.replace(
      ':competition_id',
      competition_id.toString()
    )}`;
    return this.pijamHttpClient.get<CompetitionAnalyticsResponse>(endpoint);
  }

  getEnrollmentStatus(competition_id: number) {
    const endpoint = `${endpoints.GET_ENROLLMENT_STATUS.replace(
      ':competition_id',
      competition_id.toString()
    )}`;
    return this.pijamHttpClient.get<CompetitionEnrollmentStatusResponse>(
      endpoint
    );
  }

  getCompetitionCompletionStatus(competition_id: number) {
    const endpoint = `${endpoints.GET_COMPETITION_COMPLETION_STATUS.replace(
      ':competition_id',
      competition_id.toString()
    )}`;
    return this.pijamHttpClient.get<CompetitionStatusResponse>(
      endpoint
    );
  }

  setCertificateDownloadStatus(competition_id: number) {
    const endpoint = `${endpoints.SET_CERTIFICATE_DOWNLOAD_STATUS.replace(
      ':competition_id',
      competition_id.toString()
    )}`;

    return this.pijamHttpClient.post(endpoint, {});
  }

  enrollIntoCompetition(
    competition_id: number, 
    referralCode?: string, 
    visibility_type?: 'teacher' | 'student' | 'all'
  ) {
    let endpoint = `${endpoints.ENROLL_COMPETITION.replace(
      ':competition_id',
      competition_id.toString()
    )}`;
    if (visibility_type) {
      endpoint += `?visibility_type=${visibility_type}`;
    }
    const requestBody = {
      referral_code: referralCode || ""
    };
    return this.pijamHttpClient.post(
      endpoint,
      requestBody
    ) as Observable<CompetitionEnrollmentStatusResponse>;
  }

  getCompetitionScoreCard(competition_id: number) {
    const endpoint = `${endpoints.GET_COMPETITION_SCORECARD.replace(
      ':competition_id',
      competition_id.toString()
    )}`;
    return this.pijamHttpClient.get<CompetitionScoreCardResponse>(endpoint);
  }

  getCompetitionFeedback(competition_id: number) {
    const endpoint = `${endpoints.GET_COMPETITION_FEEDBACK_STATUS.replace(
      ':competition_id',
      competition_id.toString()
    )}`;
    return this.pijamHttpClient.get<CompetitionScoreCardResponse>(endpoint);
  }

  setCompetitionFeedback(competition_id: number, feedbackData:FeedbackData) {
    const endpoint = `${endpoints.SET_COMPETITION_FEEDBACK.replace(':competition_id', competition_id.toString())}`;
    const requestBody: any = {
      feedback: feedbackData
    };

    return this.pijamHttpClient.post(endpoint, requestBody);
  }

  setCompetitionFeedbackCertificate(competition_id: number, rating: number) {
    const endpoint = `${endpoints.SET_COMPETITION_FEEDBACK.replace(':competition_id', competition_id.toString())}`;
    const requestBody: any = {
      feedback: {
        rating: rating
      }
    };

    return this.pijamHttpClient.post(endpoint, requestBody);
  }

  getCompetitionDetailsMetadata(competition_id: number, locale?: string): Observable<DetailsStructureResponse> {
    let endPoint = `${endpoints.GET_COMPETITION_DETAILS.replace(
      ':competition_id',
      competition_id.toString()
    )}`;
    
    if (locale) {
      endPoint += `?locale=${locale}`;
    }
    
    return this.pijamHttpClient.get<DetailsStructureResponse>(endPoint); 
  }
  
  updateTopicStatus(competitionId: number, lessonId: number, topicId: number, action: string, submittedInfo: any) {
    const endpoint = `${endpoints.UPDATE_COMPETITION_TOPIC_STATUS.replace(
      ':competition_id',
      competitionId.toString()
    ).replace(
      ':lesson_id',
      lessonId.toString()
    ).replace(
      ':topic_id',
      topicId.toString()
    )}`;

    const requestBody = {
      action: action,
      submitted_info: submittedInfo
    };

    return this.pijamHttpClient.post(
      endpoint,
      requestBody
    ) as Observable<CompetitionTopicUpdateResponse>;
  }

  getTopicResponse(competitionId: number, lessonId: number, topicId: number) {
    const endpoint = `${endpoints.GET_TOPIC_RESPONSE.replace(
      ':competition_id',
      competitionId.toString()
    ).replace(
      ':lesson_id',
      lessonId.toString()
    ).replace(
      ':topic_id',
      topicId.toString()
    )}`;

    return this.pijamHttpClient.get(endpoint)
  }
}
