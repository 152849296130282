import { Injectable } from '@angular/core';
import { Command } from './command.interface';
import { Lesson } from '../models'; // Import Lesson model
import { map } from 'rxjs/operators';
import { HourOfCodeService } from '../../public-api';
import { GetHoc } from './get-hoc.command';
import { Observable } from 'rxjs';

export interface GetHocLessonOptions {
  hocId: number,
  forceRefresh?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class GetHocLesson implements Command<GetHocLessonOptions, Lesson> {
  constructor(
    private getHoc: GetHoc
  ) {}

  execute(options: GetHocLessonOptions): Observable<Lesson> {
    const { hocId,forceRefresh } = options;

    return this.getHoc.execute({ hocId,forceRefresh }).pipe(
      map(hoc => {
        // Assuming there will always be one lesson, so directly return it
        return hoc.lessons[0];
      })
    );
  }
}
