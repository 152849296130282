import { Injectable } from '@angular/core';
import { PijamHttpClient } from '../../http-client/pijam-http-client';
import { endpoints } from '../../constants/endpoints';
import { ClientDetailsResponse } from '../../models/client.interface';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Section } from '../../models/client.interface';
@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private clientDetailsResponse!: ClientDetailsResponse;
  private verticalListData = new BehaviorSubject<Section>({ 
    type: '',
    title: '',
    data: []
  });

  constructor(private pijamHttpClient: PijamHttpClient) {}

  getClientDetails(clientName: string): Observable<ClientDetailsResponse> {
    if (this.clientDetailsResponse) {
      return of(this.clientDetailsResponse);
    }

    const encodedClient = btoa(clientName)
    const endpoint = `${endpoints.CLIENT_DETAILS}?client=${encodedClient}`;
    return this.pijamHttpClient.get<ClientDetailsResponse>(endpoint).pipe(
      tap(response => this.clientDetailsResponse = response)
    );
  }

  setVerticalListData(data: Section): void {
    this.verticalListData.next(data);
  }

  getVerticalListData(): Observable<Section> { 
    return this.verticalListData.asObservable();
  }
  
}
