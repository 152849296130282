import { Injectable } from '@angular/core';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { CapStorageService } from '../storage/cap-storage.service';
import { Lesson } from '../../models/lesson.interface';
import { HocEnrollment } from '../../models/HourOfCode.model';
import { CoursesService } from './courses.service';
import { switchMap, skipWhile } from 'rxjs/operators';
import { HourOfCodeService } from '../hoc/hour-of-code.service';

/** Service to manage user tallies */
@Injectable({
  providedIn: 'root'
})
export class PointsService {
  /** Totals points for current user */
  #points = new BehaviorSubject<number>(0);
  refreshPoints$ = new Subject<void>();

  /** Points storage key */
  #tallyStorageKey = 'user-tally';

  constructor(
    private capStorage: CapStorageService,
    private coursesService: CoursesService
  ) {
    this.init();
  }

  /** Totals points for current user */
  get points(): Observable<number> {
    return this.#points.asObservable();
  }

  async refreshPoints() {
    const tallyLessonsPoints = (totalPoints: number, lesson: Lesson) =>
      totalPoints + lesson.topics
      .map((topic: any) => topic.status == 'complete' ? topic.rewards : 0)
      .reduce((totalPoints: number, points: number) => totalPoints + points, 0);
    const hoc = await this.capStorage.getValue<HocEnrollment>(HourOfCodeService.hocStorageKey);

    this.coursesService.getActiveCoursesEnrollments().pipe(
      skipWhile(courses => courses === null),
      switchMap(courses  => {
        let points = 0;
        if (courses) {
          courses.map((course: any) => {
            points += course.stages.reduce(
              (totalPoints: number, stage: any) => totalPoints + stage.lessons.reduce(tallyLessonsPoints, totalPoints),
              0
            );
          });
        }

        return of(points);
      })
    )
      .subscribe((points: number) => {
        if (hoc && hoc.lessons) {
          points += hoc.lessons.reduce(tallyLessonsPoints, points);
        }

        this.capStorage.setValue(this.#tallyStorageKey, { points });
        this.#points.next(points);
      });
  }

    /** Reset the tallies */
  reset() {
    this.capStorage.setValue<number>(this.#tallyStorageKey, 0);
  }

  /** Inits tallies observable */
  private async init() {
    const { points } = await this.capStorage.getValue<{ points: number }>(this.#tallyStorageKey, { points: 0 });
    this.#points.next(points);
  }
}
